import NiceModal from "@ebay/nice-modal-react";
import React, { FC, useCallback } from "react";

import { NotificationProvider } from "~/providers/Notification";

type Action =
  | {
      type: "OPEN_SIDEBAR";
    }
  | {
      type: "CLOSE_SIDEBAR";
    }
  | {
      type: "OPEN_DROPDOWN";
    }
  | {
      type: "CLOSE_DROPDOWN";
    }
  | {
      type: "OPEN_MODAL";
    }
  | {
      type: "CLOSE_MODAL";
    }
  | {
      type: "SET_MODAL_VIEW";
      view: MODAL_VIEWS;
    }
  | {
      type: "SET_SIDEBAR_VIEW";
      view: SIDEBAR_VIEWS;
    }
  | {
      type: "SET_USER_AVATAR";
      value: string;
    };

export type MODAL_VIEWS = "PREVIEW" | "dashboard/Chart/MonthlyTopExpenseCategoriesChart";

export type SIDEBAR_VIEWS = "NOTIFICATION_VIEW";

export interface State {
  displaySidebar: boolean;
  displayDropdown: boolean;
  displayModal: boolean;
  modalView: MODAL_VIEWS;
  sidebarView: SIDEBAR_VIEWS;
  userAvatar: string;
}

const initialState: State = {
  displaySidebar: false,
  displayDropdown: false,
  displayModal: false,
  modalView: "PREVIEW",
  sidebarView: "NOTIFICATION_VIEW",
  //TODO, userAvatar should ideally flow from the backend
  userAvatar: "",
};

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case "OPEN_SIDEBAR": {
      return {
        ...state,
        displaySidebar: true,
      };
    }
    case "CLOSE_SIDEBAR": {
      return {
        ...state,
        displaySidebar: false,
      };
    }
    case "OPEN_DROPDOWN": {
      return {
        ...state,
        displayDropdown: true,
      };
    }
    case "CLOSE_DROPDOWN": {
      return {
        ...state,
        displayDropdown: false,
      };
    }
    case "OPEN_MODAL": {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      };
    }
    case "CLOSE_MODAL": {
      return {
        ...state,
        displayModal: false,
      };
    }
    case "SET_MODAL_VIEW": {
      return {
        ...state,
        modalView: action.view,
      };
    }
    case "SET_SIDEBAR_VIEW": {
      return {
        ...state,
        sidebarView: action.view,
      };
    }
    case "SET_USER_AVATAR": {
      return {
        ...state,
        userAvatar: action.value,
      };
    }
  }
}

export interface UIContextState {
  context: State;
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
  closeSidebarIfPresent: () => void;
  openDropdown: () => void;
  closeDropdown: () => void;
  openModal: () => void;
  closeModal: () => void;
  setModalView: (view: MODAL_VIEWS) => void;
  setSidebarView: (view: SIDEBAR_VIEWS) => void;
  setUserAvatar: (value: string) => void;
}

// | any is here so that i don't have to mock the actions, makes type of UIContext any, but i deal with that casting in the useUI
// and only useUI is the rest of the app
export const UIContext = React.createContext<UIContextState | any>(null);

UIContext.displayName = "UIContext";

export const UIProvider: FC<React.PropsWithChildren<unknown>> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);

  const openSidebar = useCallback(() => dispatch({ type: "OPEN_SIDEBAR" }), [dispatch]);
  const closeSidebar = useCallback(() => dispatch({ type: "CLOSE_SIDEBAR" }), [dispatch]);
  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({ type: "CLOSE_SIDEBAR" })
        : dispatch({ type: "OPEN_SIDEBAR" }),
    [dispatch, state.displaySidebar],
  );
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: "CLOSE_SIDEBAR" }),
    [dispatch, state.displaySidebar],
  );

  const openDropdown = useCallback(() => dispatch({ type: "OPEN_DROPDOWN" }), [dispatch]);
  const closeDropdown = useCallback(() => dispatch({ type: "CLOSE_DROPDOWN" }), [dispatch]);

  const openModal = useCallback(() => dispatch({ type: "OPEN_MODAL" }), [dispatch]);
  const closeModal = useCallback(() => dispatch({ type: "CLOSE_MODAL" }), [dispatch]);

  const setUserAvatar = useCallback(
    (value: string) => dispatch({ type: "SET_USER_AVATAR", value }),
    [dispatch],
  );

  const setModalView = useCallback(
    (view: MODAL_VIEWS) => dispatch({ type: "SET_MODAL_VIEW", view }),
    [dispatch],
  );

  const setSidebarView = useCallback(
    (view: SIDEBAR_VIEWS) => dispatch({ type: "SET_SIDEBAR_VIEW", view }),
    [dispatch],
  );

  return (
    <UIContext.Provider
      value={{
        context: state,
        openSidebar,
        closeSidebar,
        toggleSidebar,
        closeSidebarIfPresent,
        openDropdown,
        closeDropdown,
        openModal,
        closeModal,
        setModalView,
        setSidebarView,
        setUserAvatar,
      }}
      {...props}
    />
  );
};

export const ManagedUIContext: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <UIProvider>
    <NotificationProvider>
      <NiceModal.Provider>{children}</NiceModal.Provider>
    </NotificationProvider>
  </UIProvider>
);

export const useUI = () => {
  const context = React.useContext<UIContextState>(UIContext);

  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};
