import { noop } from "lodash";
import getConfig from "next/config";
import React from "react";

type NotificationType = Components.Schemas.NotificationTypeEnum;
export type Notification = Components.Schemas.Notification & {
  id: number;
  dismiss_after?: number;
};

type NotificationContextType = {
  notifications: Notification[];
  addNotification: (message: string, type?: NotificationType, dismiss_after?: number) => void;
  dismiss: (id: number) => void;
};

const NotificationContext = React.createContext<NotificationContextType>(
  //used only in unit test or when context is not present
  {
    notifications: [],
    addNotification: noop,
    dismiss: noop,
  },
);

export const NotificationProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  // connect to ws and listen for notifications
  React.useEffect(() => {
    const { publicRuntimeConfig } = getConfig();
    const ws = new WebSocket(`${publicRuntimeConfig.PUBLIC_WS_SERVER_URI}/ws/connect?user_id=1`);
    ws.onmessage = (e: MessageEvent) => {
      const data = JSON.parse(e.data);
      addNotification(`${data.title} ${data.message}`.trim(), data.type);
    };

    return () => {
      ws.close();
    };
  }, []);

  function addNotification(
    message: string,
    type: NotificationType = "SUCCESS",
    dismiss_after = 5000,
  ) {
    setNotifications((notifications) => {
      const id = Math.random();

      return [
        ...notifications,
        { id, message, type, dismiss_after, timestamp: new Date().toISOString(), title: "title" },
      ];
    });
  }

  function dismiss(id: number) {
    setNotifications((notifications) => notifications.filter((n) => n.id != id));
  }

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, dismiss }}>
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotification() {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error("useNotification must be used within an NotificationContext");
  }
  return context;
}
