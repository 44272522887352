import noop from "lodash/noop";
import React from "react";

type AuthContext = {
  user: Components.Schemas.UserProfile | null;
  setUser: (user: Components.Schemas.UserProfile | null) => void;
};

type AuthProviderProps = {
  initialUser: Components.Schemas.UserProfile;
};

const AuthContext = React.createContext<AuthContext>({
  user: null,
  setUser: noop,
});

/**
 * The initial value of `user` comes from the `initialUser`
 * prop which gets set by _app. We store that value in state and ignore
 * the prop from then on. The value can be changed by calling the
 * `setUser()` method in the context.
 */
export const AuthProvider: React.FC<React.PropsWithChildren<AuthProviderProps>> = ({
  children,
  initialUser,
}) => {
  const [user, setUser] = React.useState<Components.Schemas.UserProfile | null>(initialUser);

  const value = React.useMemo(
    () => ({
      user,
      setUser,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function useIsAuthenticated(): boolean {
  const context = useAuth();
  return !!context.user;
}
