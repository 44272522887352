import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import Head from "next/head";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import "react-calendar/dist/Calendar.css";
import { RecoilRoot } from "recoil";
import { SWRConfig } from "swr";

import { AuthProvider } from "~/providers/Auth";
import { ManagedUIContext } from "~/providers/ManagedUIContext";
import "~/styles/main.css";
import { fetchWrapper, handleJsonResponse } from "~/utils/fetchWrapper";

type PageProps = {
  user: any;
};

function MyApp({ Component, pageProps }: AppProps<PageProps>) {
  return (
    <AuthProvider initialUser={pageProps.user}>
      <Head>
        <title>My Money - Your personal finance manager</title>
      </Head>
      <SWRConfig
        value={{
          fetcher: (resource, init) => fetch(resource, init).then((res) => res.json()),
        }}
      >
        <RecoilRoot>
          <ManagedUIContext>
            <Component {...pageProps} />
          </ManagedUIContext>
        </RecoilRoot>
      </SWRConfig>
    </AuthProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  let sessionId = null;
  let profile = null;

  // executes only on the server-side
  const request = appContext.ctx.req;
  if (request) {
    // store on request object to avoid parsing again in WithConditionalRedirectWrapper.getInitialProps ServerCondition check
    (request as any).cookies = parseCookies(appContext.ctx);
    sessionId = (request as any).cookies.sessionid;

    if (sessionId) {
      try {
        // validate sessionId cookie
        const data: Paths.UserProfileRetrieve.Responses.$200 = await fetchWrapper
          ._fetch("/api/v1/user/profile", {}, appContext.ctx)
          .then(handleJsonResponse);

        // session is valid: valid forward csrftoken to the client for future reference
        // this might not be needed since, all /api/* routes hit django directly and it should be set on a get which should always happen before the get,
        // one issue though is with signout
        profile = data;
        setCookie(appContext.ctx, "csrftoken", data.csrftoken);
      } catch (error) {
        // session invalid: remove cookie
        destroyCookie(appContext.ctx, "sessionid");
      }
    }
  }

  // each page should be wrapped with with hoc, that define `getInitialProps`, checks auth and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  appProps.pageProps["user"] = profile;

  return { ...appProps };
};

export default MyApp;
