import dayjs from "dayjs";
import forOwn from "lodash/forOwn";
import { NextPageContext } from "next";
import { parseCookies } from "nookies";

export function isServer(): boolean {
  return !(typeof window !== "undefined" && window.document);
}

async function _fetch(
  path: string,
  config: RequestInit,
  ctx?: NextPageContext,
): Promise<Response> {
  const url = `${isServer() ? process.env.API_SERVER_URI : ""}${path}`;

  const cookies = parseCookies(ctx);

  const request = new Request(url, {
    ...config,
    headers: {
      "X-CSRFToken": cookies.csrftoken,
      ...(isServer() ? { Cookie: ctx?.req?.headers.cookie } : {}),
      ...config.headers,
    },
    credentials: isServer() ? "include" : "same-origin",
  });
  return fetch(request);
}

export function handleJsonResponse(response: Response) {
  if (response.ok) {
    return response.json().catch(() => ({}));
  } else {
    if (Number(response.headers.get("content-length"))) {
      return response.json().then((error) => Promise.reject(error));
    } else return Promise.reject(response.status);
  }
}

function handleTextResponse(response: Response) {
  if (!response.ok) {
    return response.text().then((error) => Promise.reject(error));
  }

  return response.text();
}

async function get<T>(path: string, config?: RequestInit): Promise<T> {
  const init: RequestInit = {
    ...config,
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return await _fetch(path, init).then(handleJsonResponse);
}

async function getText(path: string, config?: RequestInit): Promise<string> {
  const init: RequestInit = { ...config, method: "get" };
  return await _fetch(path, init).then(handleTextResponse);
}

async function post<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
  const init: RequestInit = {
    ...config,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };
  return await _fetch(path, init).then(handleJsonResponse);
}

async function upload<T>(path: string, files: FileList, config?: RequestInit): Promise<T> {
  const body = new FormData();
  body.append("file", files[0]);

  const init: RequestInit = {
    ...config,
    method: "POST",
    body,
  };
  return await _fetch(path, init).then(handleJsonResponse);
}

async function put<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
  const init: RequestInit = {
    ...config,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };
  return await _fetch(path, init).then(handleJsonResponse);
}

async function patch<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
  const init: RequestInit = {
    ...config,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };
  return await _fetch(path, init).then(handleJsonResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete<T>(path: string, config?: RequestInit): Promise<T> {
  const init: RequestInit = {
    ...config,
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };
  return await _fetch(path, init).then(handleJsonResponse);
}

export const fetchWrapper = {
  _fetch,

  get,
  post,
  put,
  patch,
  delete: _delete,

  getText,
  upload,
};

export function toUrl(url: string, queryParams: any): string {
  return [url, encodeQueryParams(queryParams)].join("?");
}

export function encodeQueryParams(queryParams: any): string {
  function getURIComponent(key: string, value: unknown) {
    const val = encodeURIComponent(toString(value));
    return val ? `${key}=${val}` : "";
  }

  function joinByEquals([key, value]: [string, unknown]) {
    if (Array.isArray(value)) {
      const tmp: string[] = [];
      forOwn(value, (item) => {
        tmp.push(getURIComponent(key, item));
      });
      return tmp.filter((i) => i !== "").join("&");
    } else {
      return getURIComponent(key, value);
    }
  }

  return Object.entries(queryParams)
    .map(joinByEquals)
    .filter((i) => i !== "")
    .join("&");
}

export function toString(value: any) {
  if (value == null) {
    return "";
  } else if (value instanceof Date) {
    return dayjs(value).toISOString();
  } else if (dayjs.isDayjs(value)) {
    return value.toISOString();
  } else {
    return String(value);
  }
}
